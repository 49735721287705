<template>
  <el-container class="container" ref="container" style="display: flex;flex-direction:column;" >
    <div class="myscrollbar" style="width:100%;overflow:auto">
    <div class="flex_row" style="justify-content:space-between;
        width:100%;margin-top:10px;min-width:800px;" :style="'max-width:'+divwidth+'px;'">
      <div class="flex_col" style="width:56%;flex-grow:1;flex-shrink:1">
        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-autocomplete  autocomplete="off" :fetch-suggestions="ContactAutoComp" :size="size" v-model="customerHead.contact" 
          :maxlength="50" clearable 
                @keyup.enter.native="EnterFun($event,'contact',customerHead.contact)" style="margin-left:10px;width:50%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size"  plain class="button" 
                  @click="queryCustList('contact',customerHead.contact)">名称</el-button>
          </el-autocomplete>
          <el-autocomplete  autocomplete="on" :fetch-suggestions="mobileAutoComp" :size="size" 
          v-model="customerHead.mobile" :maxlength="11" clearable @select="queryCustList('mobile',customerHead.mobile)"
                @keyup.enter.native="EnterFun($event,'mobile',customerHead.mobile)" style="margin-left:10px;width:40%;flex-grow:1;flex-shrink:1">
                <el-button type="success" slot="prepend" :size="size" plain class="button"
                  @click="queryCustList('mobile',customerHead.mobile)">联系</el-button>
                  
          </el-autocomplete>
        </div>

        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-input  autocomplete="off" :size="size" v-model="customerHead.contactaddr" maxlength="150" clearable 
                @keyup.enter.native="EnterFun($event,'contactaddr',customerHead.contactaddr)" style="margin-left:10px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="queryCustList('contactaddr',customerHead.contactaddr)">地址</el-button>
              
          </el-input>
          
        </div>
        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <div class="flex_row_left" style="width:50%;margin-left:10px;flex-grow:1">
              <el-button type="success"  :size="size" plain class="button"  
                  @click="queryCustList('查类型',customerHead.intention)">查类型</el-button>
              <el-select v-model="customerHead.intention" clearable :size="size" 
                  placeholder="客户类型" style="margin-left:0px;flex-grow:1">
                <el-option
                  v-for="item in intentionList"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
          </div>
          <div class="flex_row_left " style="width:40%;margin-left:10px;flex-grow:1">
              <el-button type="success"  :size="size" plain class="button"  style="margin-left:0px;"
                  @click="getCustList('查站点',customerHead.station)">查站点</el-button>
              <el-select v-model="customerHead.station" clearable :size="size" 
                  placeholder="站点" style="margin-left:0px;flex-grow:1">
                <el-option
                  v-for="item in stationList"
                  :key="item.station"
                  :label="item.station"
                  :value="item.station">
                </el-option>
              </el-select>
          </div>
          
        </div>

        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-input  autocomplete="off" :size="size" v-model="customerHead.m_identity" maxlength="18" clearable 
                @keyup.enter.native="EnterFun($event,'m_identity',customerHead.m_identity)" style="margin-left:10px;width:50%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
          @click="queryCustList('m_identity',customerHead.m_identity)">身份证</el-button>
          </el-input>
          <el-autocomplete  autocomplete="off" :fetch-suggestions="SyzbhAutoComp" :size="size" 
          v-model="customerHead.m_syzbh" :maxlength="10" clearable @select="queryCustList('m_syzbh',customerHead.m_syzbh)"
                @keyup.enter.native="EnterFun($event,'m_syzbh',customerHead.m_syzbh)" style="margin-left:10px;width:40%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size" plain class="button"
                  @click="queryCustList('m_syzbh',customerHead.m_syzbh)">使用证</el-button>
          </el-autocomplete>

        </div>
      </div>
      <div class="flex_col" style="" >
          <el-button type="success"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="getCustList('查站长',customerHead.station)">查站长</el-button>
          <el-button type="success"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="getCustList('查送气工',customerHead.station)">查送气工</el-button>
          <el-button type="primary"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="SaveCustomer()">保 存</el-button>
          <el-button type="success"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="getCustList('最近操作')">最近操作</el-button>
      </div>
      <div class="flex_col" style="width:30%;flex-grow:1;flex-shrink:1;margin:0 10px 5px;">
          <div class="flex_row_left " style="width:100%;margin-bottom:5px;">
              <el-button type="success"  :size="size" plain class="button"
                  @click="getCustList('查站长',customerHead.m_stationer)">查站长</el-button>
              <el-select v-model="customerHead.m_stationer" clearable :size="size" 
                  placeholder="选站点" style="margin-left:0px;flex-grow:1">
                <el-option
                  v-for="item in stationList"
                  :key="item.station"
                  :label="item.station"
                  :value="item.station">
                </el-option>
              </el-select>
              <el-button type="success"  :size="size" plain class="button" style="margin-left:10px;"
                  @click="getCustList('查送气工',customerHead.m_worker)">查送气</el-button>
              <el-select v-model="customerHead.m_worker" clearable :size="size" 
                  placeholder="送气工" style="flex-grow:1">
                <el-option
                  v-for="item in stationList"
                  :key="item.station"
                  :label="item.station"
                  :value="item.station">
                </el-option>
              </el-select>
          </div>
          <el-input  autocomplete="off" :size="size" v-model="customerHead.zdy2" maxlength="50" clearable 
                style="margin-bottom:5px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="getCustList('查保险')">查保险</el-button>
          </el-input>
          <el-input  autocomplete="off" :size="size" v-model="customerHead.anjian" maxlength="50" clearable readonly
                 style="margin-bottom:5px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="getCustList('anjian')">查安检</el-button>
          </el-input>
          <div class="flex_row_left " style="width:100%;margin-bottom:5px;">
            <el-input  autocomplete="off" :size="size" v-model="customerHead.khbh" maxlength="50" clearable
                 style1="margin-bottom:5px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="ModifyKhbh(customerHead.khbh)">改编号</el-button>
                <el-button type="danger" slot="append" icon="el-icon-delete" :size="size" plain v-if="isPc"
                  style="width:42px;padding:10px 0px;" @click="ModifyKhbh('')"></el-button>
          </el-input>
            <el-button type="success" plain :size="size" class="button" style="width:100px;margin-left:10px;" :loading="downfileloading"
              @click="exportToExcel()">输出</el-button>
          </div>
      </div>
    </div>
    </div>
    <!-- <div style="height:5px;background: #efeff4;"></div> -->
    <el-main ref="elmaincards"  style="justify-content: flex-start;display: flex;flex-direction:column;overflow-y: auto;"
      >
        <el-table :height="tableHeight" :size="size" ref="CustomerTable" :data="customerlists" highlight-current-row 
				v-loading="listLoading" element-loading-text="Loading..." :fit="true" @current-change="handleCurrentChange"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor">
        <div slot="empty" style="float:left">暂无数据</div>
					<!--<el-table-column type="selection" width="55"></el-table-column>-->
					<!-- a.m_price,a.m_station,a.m_stats,a.m_caddress,a.m_cmemo,a.m_worker,
	a.m_scantime,convert(varchar(19),a.m_lasttime,120) m_lasttime,c.zdy2,anjian,b.m_identity,a.m_usecode,b.m_syzbh,a.m_lxmobile -->

					<el-table-column prop="px" label="序" width="38" align="center" show-overflow-tooltip>
            <div slot-scope="scope" style="text-align:left;font-size:12px;">
                {{scope.row.px}}</div>
          </el-table-column>
					<el-table-column prop="contact" label="名称" width="140" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="mobile" label="联系电话" width="110" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="station" label="站点" width="80"></el-table-column>
          <el-table-column prop="intention" label="类型" width="80"></el-table-column>
          <el-table-column prop="m_syzbh" label="使用证" width="90"></el-table-column>
          <el-table-column prop="contactaddr" label="地址" width="300" min-width="60" show-overflow-tooltip>
            <div slot-scope="scope" style="font-size:13px;">
                {{scope.row.contactaddr}}</div>
          </el-table-column>
          <el-table-column prop="m_lasttime" label="最近操作" width="160" min-width="60" show-overflow-tooltip>
            <div slot-scope="scope" style="font-size:13px;">
                {{scope.row.m_lasttime}}</div>
          </el-table-column>
          <el-table-column prop="m_identity" label="身份证" width="70" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="khbh" label="客户编号" width="100" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="anjian" label="安检" width="60" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="anjianpzdate" label="拍照" width="60" min-width="60" show-overflow-tooltip>
          <div slot-scope="scope" style="font-size:13px;">
                {{scope.row.anjianpzdate}}</div>
          </el-table-column>
          <el-table-column prop="m_stationer" label="站长" width="60" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_worker" label="送气工" width="70" min-width="60" show-overflow-tooltip>
          </el-table-column>
         <el-table-column prop="zdy2" label="保险" width="60" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="upflag" label="UP" width="45"></el-table-column>

        </el-table>
    </el-main>
    
        <el-pagination style="margin-bottom:5px;" 
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page.sync="pageno"
        :page-sizes="[20, 50, 100]"
        :page-size="pagesize"
        :layout="pagelayout"
        :total="totalcount">
      </el-pagination>
    
  </el-container>
</template>

<script>
/* eslint-disable */
import sfutil from '../../Api/identity.js';
//import exceltil from '../../Api/export2Excel.js';
//import loadBMap from '../../plugins/loadBMap.js'
export default {
  data() {
    return { 
      size: 'medium',//medium,small,mini,
      divwidth:950,
      elmaincard:500,
      elmaincardh:700,
      isPc:false,
      stationList:[],//站点列表
      intentionList:["","居民","非居民","餐饮","工程"],//客户类型
      canCall:false,
      StaffID:"",//弹屏获取的uc3账号
      StaffName:"",//Uc3名称
      tableHeight:500,//表格高度
      CustomerDrawer:{},
      customerHead:{//客户信息,订单头
        khbh:"",contact:"",station:"",contactaddr:"",mobile:"",m_identity:"",intention:"",
	      m_syzbh:"",m_cmemo:"",m_worker:"",zdy2:"",m_lxmobile:"",m_stationer:"",recid:0,
      },
      CallerNumAuto:[],//自动建议录入
      //mobileAuto:[],//自动建议录入
      SyzbhAuto:[],
      customerlists:[],
      listLoading:false,//表格加载时
      showSelectWork:false,//显示送气工开关
      canEditAddress:false,
      custLocation:{address:'',},//客户地址
      worksLocation:[],//送气工地址
      markers:[],//地图覆盖物
      canEditAddress:false,//能否修改地址
      pageno:1,
      pagesize:20,
      totalcount:0,//记录总数
      pagelayout:"total,sizes,prev,pager,next,jumper",
      downfileloading:false, //下载时
      mytimers:[],//定时器数组，便于清理
    }
  },
  computed: {
    tabBarWidth(){
      return this.$store.getters.GettabBarInfo("tabBarWidth");
    },
    mobileAuto() {
      return this.$store.getters.GetmobileAuto;
    },
  },
  watch: {
    tabBarWidth(){
      this.initDivSize();
    }
  },
  methods: {
    myclearTimer(){
      this.mytimers.forEach(e=>{
        if (e) clearTimeout(e)
      })
      this.mytimers=[];
    },
    exportToExcel(){
      this.downfileloading=true;
      let data = {
        lx: "getcustlistexcel",
        procext:"order"
      };
      this.$api.post("",data,(res) => {
          if (res["status"] == "1") {
            var headers = res["headers"].split(',');
			      var filterVal = res["fields"].split(',');
            var filetitle = res["filetitle"];
            var jdata=res.records
            if (jdata.length==0){
              this.$message("无数据，请先查询");
              this.downfileloading=false;
              return
            }
            console.log("拿到数据。。。。",jdata)
            //console.log(headers,filterVal,filetitle)
            this.$api.export2Excel(headers, filterVal, jdata, filetitle);
            this.downfileloading=false;
            return;
            const link = window.document.createElement('a')
            link.style.display = 'none'
            //link.href ='http://www.yh59481717.com/downfiles/客户27-11.xlsx'
            link.href ='http://116.247.92.114:8081/downfiles/客户27-11.xlsx'
            link.setAttribute('download', 'mao')
            //document.body.appendChild(link)
            link.click()
            
          }else{
            this.$message.error(res.message);
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.downfileloading=false;
        },
        (failure) => {this.listLoading=false;}
      );
    },
    handleSizeChange(val) {
      this.pagesize=val
      let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
      pagesizes["custmodify"]=val
      localStorage.setItem("pagesizes",JSON.stringify(pagesizes))
      if (this.totalcount==0) return
      this.getCustList('mobile','mobile',this.pageno)
    },
    handlePageChange(val) {
      if (this.totalcount==0) return
      this.getCustList('mobile','mobile',val)
    },
    ModifyKhbh(khbh) {
      let mobile=this.$api.myFilter(this.customerHead.mobile);
      //let khbh=this.$api.myFilter(this.customerHead.khbh);
      if (!this.$api.checkMobile(mobile)){
        this.$message.error("请输入联系电话");
        return
      }
      this.$confirm("真的要"+(khbh ? "修改客户编号【"+khbh+"】吗?":"删除客户【"+mobile+"】吗？"), "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            lx: "modifyKhbh",
            sqlone:mobile,
            sqltwo:khbh,
            procext:"order",
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1") {
                let js=res["js"];
                if (js=="1"){
                  this.$message.success(res["message"]);
                  return
                }
              }
              this.$message.error(res["message"]);
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {}
          );
        })
      .catch(() => {})
      
      
    },
    SaveCustomer(){
      if (this.StaffID==""){
        this.$message.error("此操作无权限");
        return;
      }
      let recid=this.customerHead.recid
      if (recid<1){
        this.$message.error("请选择一个记录");
        return;
      }
      
      for(var k in this.customerHead){
        this.customerHead[k]=this.$api.myFilter(this.customerHead[k].trim())
      }
      //let mobile=this.customerHead.mobile;
      let kh=this.customerHead.contact+this.customerHead.mobile
      // if (mobile!=this.customerlists[idx].mobile){
      //   this.$message.error("客户的电话不能修改");
      //   return;
      // }
      let sfz=this.customerHead.m_identity
      if (sfz){
        let sfzmsg=sfutil.SFID(sfz);
        if (sfzmsg){
          this.$message.error(sfzmsg);
          return;
        }
      }
      let m_syzbh=this.customerHead.m_syzbh
      if (!(m_syzbh) && /^[0-9]\d{6,7}$/.test(m_syzbh)){
        this.$message.error("使用证有错误7-8位");
        return;
      }
      if (this.customerHead.contactaddr.length<2){
        this.$message.error("地址太短了，至少2个字啊");
        return;
      }
      if (this.customerHead.intention==""){
        this.$message.error("居民类型必须");
        return;
      }
      if (this.customerHead.station.length!=2){
        this.$message.error("请选择正确的所属站点");
        return;
      }
      this.$confirm("真的要修改【"+kh+"】的资料吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "savecustomer",
            sqltwo:recid,
            sqlone:JSON.stringify(this.customerHead),
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1") {
                let js=res["js"];
                let jl=res["records"][0];
                if (js=="1"){
                  this.$message.success(res["message"]);
                  var idx=this.customerlists.findIndex(item=>item.recid==recid)
                  if (idx>-1){
                    for(var k in jl){
                      this.customerlists[idx][k]=jl[k]
                    }
                  }
                  for(var k in jl){
                      this.customerHead[k]=jl[k]
                  }
                  return
                }
              } 
              this.$message.error(res["message"]);
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    dispWorkLocation(){
      console.log("1")
      var that = this
      if (!this.showSelectWork) return;
      if ((typeof that.map) == 'undefined') return;
      
      that.map.clearOverlays();
      //that.map.removeOverlay(preMarker);
      let c=this.custLocation
      if (c.length==0) return
      that.markers=[];
      let iconCust = new BMap.Icon("http://www.yh59481717.com/images/map-4.png", new BMap.Size(44, 50)); 
      let iconStat = new BMap.Icon("http://www.yh59481717.com/images/map-2.png", new BMap.Size(44, 50)); 
      let p1=c.statXY.split("|")[0];
      let p2=c.statXY.split("|")[1];
      let point = new BMap.Point(p1,p2);
      //that.map.centerAndZoom(point,14)
      let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+c.station+"</div>",{point:new BMap.Point(p1,p2),offset:new BMap.Size(-6,-20)});
      label.setStyle({background:"#bbfc9b",color:"#333",cursor:"pointer"});
      
      let marker = new BMap.Marker(point, { enableDragging: true,icon:iconStat});
      marker.setLabel(label);
      that.map.addOverlay(marker);

      p1=(c.custXY?c.custXY:c.statXY).split("|")[0];
      p2=(c.custXY?c.custXY:c.statXY).split("|")[1];
      point = new BMap.Point(p1,p2);
that.map.centerAndZoom(point,14)
      label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+c.Contact+"</div>",{point:new BMap.Point(p1,p2),offset:new BMap.Size(3,-23)});
      label.setStyle({borderColor:"#808080",color:"#333",cursor:"pointer"});

      marker = new BMap.Marker(point, { enableDragging: true});
      marker.setLabel(label);
      if (1==0 && that.canEditAddress){
        marker.addEventListener('dragend', function(e) {
          this.getAddrByPoint(e.point);
        })
        marker.addEventListener('click', function(e) {
          that.EditAddrByPoint(e.point);
        })
      }
      that.markers.push(marker);
      that.map.addOverlay(marker);
      marker.setAnimation(BMAP_ANIMATION_BOUNCE);
      p1=c.statXY.split("|")[0];
      p2=c.statXY.split("|")[1];
      point = new BMap.Point(p1,p2);
      
      that.worksLocation.forEach((s,index) => {
          p1=(s.workXY?s.workXY:s.statXY).split("|")[0];
          p2=(s.workXY?s.workXY:s.statXY).split("|")[1];
          point = new BMap.Point(p1,p2);
          label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+s.workname+"</div>",{point:new BMap.Point(p1,p2),offset:new BMap.Size(3,-23)});
          if (s.workname==that.customerHead.m_worker){
            label.setStyle({background:"#a0cfff",color:"#333",cursor:"pointer"});
          }else{
            label.setStyle({background:"#ff0000",color:"#eee",cursor:"pointer"});
          }
          label.addEventListener('click', function(e) {
            that.SetWorker(s.workname);
          })
          marker = new BMap.Marker(point, { enableDragging: true,icon:iconCust });
          marker.setLabel(label);
          that.map.addOverlay(marker);
      })
      //marker=that.markers[0]
      //that.map.removeOverlay(marker);
       //p1=c.statXY.split("|")[0];
       //p2=c.statXY.split("|")[1];
       //point = new BMap.Point(p1,p2);
      //marker.setPosition(point)
    },
    SetWorker(worker){
      console.log(worker)
      this.$confirm("真的要选择【"+worker+"】作为送气工吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          this.customerHead.m_worker=worker
          this.showSelectWork=false
          })
      .catch(() => {});
    },
    mobileAutoComp(queryString, cb){ //联系电话建议录入
      cb(this.mobileAuto);
    },
    SyzbhAutoComp(queryString, cb){
      cb(this.SyzbhAuto);
    },
    ContactAutoComp(queryString, cb){
      cb([]);
    },
    handleCurrentChange(currentRow){
      //console.log(currentRow)
      if (currentRow===null) return
      if (currentRow.length==0) return
      //this.xsrecid=currentRow.recid;
      for(var k in currentRow){
        //console.log(k,currentRow[k])
        this.customerHead[k]=currentRow[k];
      }
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f0f0f0;'
        }
    },
    queryCustList(iResoure,queryval){ //根据呼叫电话查信息
      let val=this.$api.myFilterno(queryval);
      if (iResoure!='m_syzbh' && val==""){
        this.$message.error("关键字不能为空");
        return
      }
      if (iResoure=='mobile'){
        if (val.length>7 && this.$api.checkMobile(val) && this.mobileAuto.findIndex(item=>item.value==val)==-1){
          //if (this.mobileAuto.length>6){
          //  this.mobileAuto.pop();
          //}
          this.$store.commit("SetmobileAuto",val);
          //this.mobileAuto.unshift({"value":val})
        }
      }
      if (iResoure=='m_syzbh'){
        if (val && val.length<7) {
          this.$message.error("使用证号码太短了，至少7位");
          return;
        }
        if (val.length>6 && this.SyzbhAuto.findIndex(item=>item.value==val)==-1){
          if (this.SyzbhAuto.length>6){
            this.SyzbhAuto.pop();
          }
          this.SyzbhAuto.unshift({"value":val})
        } 
      }
      this.getCustList(iResoure,val);
    },
    EnterFun(e,iResoure,queryval){
      //var keyCode = window.event? e.keyCode:e.which;
      //if (keyCode!=13) return
      //console.log(this.CallerNum,iResoure)
      this.queryCustList(iResoure,queryval)
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      let divwidth=this.$store.getters.GettabBarInfo("tabBarWidth");
      if (this.isPc){
        this.pagelayout="total,sizes,prev,pager,next,jumper"
      }else{
        this.pagelayout="total,prev,pager,next"
      }
      //tabBarWidth=tabBarWidth
      //if (this.StaffID && this.isPc){

      //}
      if (!this.isPc || divwidth<950){
        this.divwidth=divwidth;
      }else{
        if (divwidth*0.8<950){
          this.divwidth=950;}
        else{this.divwidth=divwidth*0.8}
      }
      if (this.$refs.elmaincards){
        this.$nextTick(() => {
          //监听到事件后执行的业务逻辑
          
                     //console.log(element.offsetWidth,element.offsetHeight);       
                 
          
       
        let elmaincard = this.$refs.elmaincards.$el.offsetWidth*0.4
        let elmaincardh = this.$refs.container.$el.offsetHeight*0.9
        this.elmaincardh =elmaincardh;
        this.elmaincard = elmaincard<350?350:elmaincard;
        console.log("this.elmaincard",elmaincard,this.elmaincard,elmaincardh);
        });
      }
    },
    getCustBaseInfo(){
      let data = {
        lx: "getcustbaseinfo",
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.StaffID=res.StaffID;
            this.StaffName=res.StaffName;
            this.stationList=res.records;
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    getCustList(iResoure,queryval,pageno){
      if (typeof(queryval)=="undefined") queryval="";
      if (typeof(pageno)=="undefined") pageno="-1";
      this.listLoading=true
      let data = {
        lx: "getcustlist",
        sqlone:iResoure,
        sqltwo:queryval,
        pageno:pageno,
        pagesize:this.pagesize,
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.pageno=parseInt(res.pageno)
            this.totalcount=parseInt(res.count)
            this.customerlists=res.records
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.listLoading=false;
        },
        (failure) => {this.listLoading=false;}
      );
    },  
  },
  created() {
    let logininfo = JSON.parse(sessionStorage.getItem("LoginInfo")) || "";
    if (logininfo) {
      this.LoginInfo=logininfo;
    }
    let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
    if (pagesizes && pagesizes["custmodify"])
      this.pagesize=parseInt(pagesizes["custmodify"])
    this.getCustBaseInfo();
    //this.queryCustList('CallerNum','18939978600');
  },
  mounted() {
    window.onresize = () => {
      //this.initDivSize();
    };
    setTimeout(() => {
      this.initDivSize();
    }, 300);
   
  },
  beforeDestroy() {
    this.canCall=false;
    this.myclearTimer();
  },
}
</script>
<style src = "../../Styles/cover.css"></style>
<style src = "../../Styles/common.css"></style>
<style src = "../../Styles/iconfont/iconfont.css"></style>

<style>
.input-with-select .el-input-group__append{
    background-color: #fff !important;
  }
.container .el-main {
  padding: 8px 10px 10px 10px !important
}

</style>
<style scoped>
.myscrollbar::-webkit-scrollbar{
display:none;
}
.flex_row_left{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;     /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_row_right{
    display: flex;             
    justify-content: flex-end; 
    align-items: center; 
    flex-direction: row;
}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: center;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_col{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: column;
}
.subtitle{
  font-size:16px;
  color:#222222;
  line-height:30px;
  padding-left:10px;
  /* padding-top:5px;
  padding-bottom:5px; */
}
.label0{
  width:50%;
}
.label1{
  width:15%;
  text-align: center;
}
.label2{
  width:35%;
  text-align: center;
}
.labe20{
  width:30%;
}
.labe21{
  width:45%;
  text-align: center;
}
.labe22{
  width:25%;
  text-align: center;
}
.container {
  width: 100%;
  height: 100%;
  /* background-color: #a4c7f5; */
}
.el-form-item {
    margin-bottom: 0px;
}
.el-dialog{
    min-width:320px !important;
}
.el-descriptions__header{
  margin-bottom:10px !important; 
}    
.my-label{
  width:20%;
  max-width:150px;
  min-width:80px;
}
.my-content{
  min-width:100px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input1 {
  width: 200px;
}
.button {
  /* width: 100px; */
  padding-left: 10px;
  padding-right: 10px;
}
.el-card__body{
  padding:0  !important; 
}

  </style>

